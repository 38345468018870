import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-layout',
    templateUrl: './auth-layout.component.html'
})

export class AuthLayoutComponent {
    public yearNow = moment().format('YYYY');
}
