import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { AuthService } from './auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RideService {
    public headers = new Headers();
    private API_URL: any = environment.API_URL;
    private API_PREFIX: any = environment.API_PREFIX

    constructor(
        private http: Http,
        public router: Router,
        public authService: AuthService,
    ) {
        // this.authService.createJsonHeader(this.headers);
        // this.authService.createAuthorizationHeader(this.headers)
    }

    public getRideList(search, status, page, per_page) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/ride?search=' + search +
                '&status=' + status + '&page=' + page + '&per_page=' + per_page, {
                    headers: this.authService.generateHeaders()
                }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public addRide(data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.API_URL + '/' + this.API_PREFIX + '/ride', data, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    //show specific event details
    public getRide(id) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/ride/' + id, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public updateRide(id, data) {
        return new Promise((resolve, reject) => {
            this.http.put(this.API_URL + '/' + this.API_PREFIX + '/ride/' + id, data, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }


    public deleteRide(id) {
        return new Promise((resolve, reject) => {
            this.http.delete(this.API_URL + '/' + this.API_PREFIX + '/ride/' + id, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    //show all staff in once //used in assign into ride
    public getAllStaff() {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/staff?status=10&get_all=true', {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }


    //show all checklist item in once //used in assign into ride
    public getAllItem() {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/checklistitem?get_all=true&status=10', {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }
}