import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'customStyling'})
export class CustomStyling implements PipeTransform {

  /**
   * return global css class by status ID
   * 
   * @param value value as a key 
   * @return css class string
   */
  transform(value) {
    
  }

  
}