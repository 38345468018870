import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { AuthService } from './auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
   providedIn: 'root'
})
export class StaffService {
   public headers = new Headers();
   private API_URL: any = environment.API_URL;
   private API_PREFIX: any = environment.API_PREFIX

   constructor(
      private http: Http,
      public router: Router,
      public authService: AuthService,
   ) {
      // this.authService.createJsonHeader(this.headers);
      // this.authService.createAuthorizationHeader(this.headers)
   }

   public getStaffList(search, role, status, page, per_page) {
      return new Promise((resolve, reject) => {
         this.http.get(this.API_URL + '/' + this.API_PREFIX + '/staff' + '?search='
            + search + '&roles=' + role + '&status=' + status
            + '&page=' + page + '&per_page=' + per_page, {
               headers: this.authService.generateHeaders()
            }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   public addStaff(data) {
      return new Promise((resolve, reject) => {
         this.http.post(this.API_URL + '/' + this.API_PREFIX + '/staff', data, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   //show specific event details
   public getStaff(id) {
      return new Promise((resolve, reject) => {
         this.http.get(this.API_URL + '/' + this.API_PREFIX + '/staff/' + id, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   public updateStaff(id, data) {
      return new Promise((resolve, reject) => {
         this.http.put(this.API_URL + '/' + this.API_PREFIX + '/staff/' + id, data, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }


   public deleteStaff(id) {
      return new Promise((resolve, reject) => {
         this.http.delete(this.API_URL + '/' + this.API_PREFIX + '/staff/' + id, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   public resetPassowrd(id) {
      return new Promise((resolve, reject) => {
         this.http.put(this.API_URL + '/' + this.API_PREFIX + '/staff/resetpassword/' + id, '', {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   
}