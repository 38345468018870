import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from "./providers/messaging.service";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './providers/auth/auth.service';
import * as moment from 'moment';
declare var $: any;

/* For UI loader */
import { Subscription } from 'rxjs';
/* For Handling session timeout */
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  deviceInfo = null;
  message;

  constructor(
    public translate: TranslateService,
    private messagingService: MessagingService,
    private userIdle: UserIdleService,
    private authService: AuthService,
    private router: Router
  ) {
    this.getFCMToken();
  }

  ngOnInit() {
    //Start watching for user inactivity.
    if (localStorage.getItem('token') != null) {
      var timeNow = moment();
      var lastActive = moment(localStorage.getItem('login_time'));

      if(timeNow.diff(lastActive, 'seconds') > 300) {
        this.doLogout();
      } else {
        this.userIdle.startWatching();
      }
    }
    // Start watching when user idle is starting and reset if user action is there.
    this.userIdle.onTimerStart().subscribe(count=> {
      // console.log(count);
      if(count == null) {
        localStorage.setItem('login_time', moment().format('YYYY-MM-DD HH:mm:ss'));
      }
      /* All mouse events that will reset the timer */
      var eventList= ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel",
      "mousedown","touchstart","touchmove","scroll","keyup"];

      for(let event of eventList) {
        document.body.addEventListener(event, () =>this.userIdle.resetTimer());
      }
    });

    this.userIdle.onTimeout().subscribe(
        () => this.doLogout()
    );
  }

  setupLanguage() {
    this.translate.addLangs(['en', 'bm']);
    this.translate.setDefaultLang('en');
    this.translate.use(localStorage.getItem('current_language') ? localStorage.getItem('current_language') : 'en');
  }

  getFCMToken() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }

  doLogout() {
    this.authService.logout();
    this.router.navigate(['/pages/login']);
    this.userIdle.stopTimer();

    $.notify({
    icon: "add_alert",
    message: 'Automatically logged out due to inactivity'
    }, {
        type: 'success',
        // timer: 4000,
        placement: {
            from: 'top',
            align: 'center'
        }
    });
}

  //   <div class="row" >
  //   <h2>
  //   {{ "HOME.TITLE" | translate }}
  // </h2>
  //   < p >
  //   {{ "HOME.TEXT" | translate }}
  // </p>
  //   < label for= "" >
  //     {{ "HOME.SELECT" | translate }}
  // <select #langselect(change) = "changeLanguage(langselect.value)"[ngModel] = "test" >
  //   <option * ngFor="let lang of translate.getLangs()"[value] = "lang" > {{ lang }}</option>
  //     < /select>
  //     < /label>
  //     < /div>

  // changeLanguage(value){
  //   this.translate.use(value);
  //   localStorage.setItem('current_language', value);
  // }
}
