import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { AuthService } from './auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
   providedIn: 'root'
})
export class EventService {
   public headers = new Headers();
   private API_URL: any = environment.API_URL;
   private API_PREFIX: any = environment.API_PREFIX

   constructor(
      private http: Http,
      public router: Router,
      public authService: AuthService,
   ) {
      // this.authService.createJsonHeader(this.headers);
      // this.authService.createAuthorizationHeader(this.headers)
   }

   public getEventList(month, year, search, status, page, per_page) {
      return new Promise((resolve, reject) => {
         this.http.get(this.API_URL + '/' + this.API_PREFIX + '/event?month=' + month +
            '&year=' + year + '&search=' + search + '&status=' + status + '&page=' + page + '&per_page=' + per_page, {
               headers: this.authService.generateHeaders()
            }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   public addEvent(data) {
      return new Promise((resolve, reject) => {
         this.http.post(this.API_URL + '/' + this.API_PREFIX + '/event', data, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   //show specific event details
   public getEvent(id) {
      return new Promise((resolve, reject) => {
         this.http.get(this.API_URL + '/' + this.API_PREFIX + '/event/' + id, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }

   public updateEvent(id, data) {
      return new Promise((resolve, reject) => {
         this.http.put(this.API_URL + '/' + this.API_PREFIX + '/event/' + id, data, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }


   public deleteEvent(id) {
      return new Promise((resolve, reject) => {
         this.http.delete(this.API_URL + '/' + this.API_PREFIX + '/event/' + id, {
            headers: this.authService.generateHeaders()
         }).map(res => res.json())
            .subscribe(data => {
               resolve(data);
            }, error => {
               reject(error.json());
            })
      });
   }
}