import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

/* Angular/fire */
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MessagingService } from './providers/messaging.service';


import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from "ngx-spinner";

import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule
} from '@angular/material';


import { JwtModule } from "@auth0/angular-jwt";
import { AuthGuardService } from "./providers/auth/auth-guard.service";
import { EventService } from "./providers/event.service";
import { StaffService } from "./providers/staff.service";
import { RideService } from "./providers/ride.service";
import { CheckListItemService } from "./providers/checklist-item.service";
import { SparePartService } from "./providers/sparepart.service";
import { DocumentService } from "./providers/document.service";
import { HelperService } from "./providers/helper.service";
import { InspectionReportService } from "./providers/inspection-report.service";
import { CustomStyling } from "./providers/custom-styling.pipe";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UserIdleModule } from 'angular-user-idle';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem("token");
}

/* Angular/fire config */
const firebaseConfig = {
  apiKey: "AIzaSyAxE5nRdR0Zo6E-3cVueA9Cs8dNDOXsa8g",
  authDomain: "euro-fun-park.firebaseapp.com",
  databaseURL: "https://euro-fun-park.firebaseio.com",
  projectId: "euro-fun-park",
  storageBucket: "",
  messagingSenderId: "1098249963147",
  appId: "1:1098249963147:web:7878847caac9a734"
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-info',
      cancelButtonClass: 'btn'
    }),
    NgxSpinnerModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({idle: 300, timeout: 150, ping: 0})
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    CustomStyling
  ],
  providers: [
    AuthGuardService, 
    MatDatepickerModule, 
    EventService, 
    StaffService, 
    RideService, 
    CheckListItemService,
    SparePartService,
    MessagingService,
    DocumentService,
    HelperService,
    InspectionReportService,
    AngularFireDatabase,
    AngularFireAuth,
    AngularFireMessaging
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
