// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const ENV: string = 'dev';
export const environment = {
  production: ENV == 'dev' ? false : true,
  API_URL: ENV == 'dev' ? 'https://dev-api.eurofunpark.com' : 'https://uat-api.eurofunpark.com', 
  API_PREFIX: 'api',
  grant_type: 'password',
  client_id: '2',
  client_secret: ENV == 'dev' ? 't0OKTe5KcD6QyNojuj4jWJOpHb1XokST1qLJEfHw' : '8pbOKUKecOZZUX1z8dhrpujWFnt17wV0hW5PDdin',
  firebase: {
    apiKey: "AIzaSyAxE5nRdR0Zo6E-3cVueA9Cs8dNDOXsa8g",
    authDomain: "euro-fun-park.firebaseapp.com",
    databaseURL: "https://euro-fun-park.firebaseio.com",
    projectId: "euro-fun-park",
    storageBucket: "",
    messagingSenderId: "1098249963147",
    appId: "1:1098249963147:web:7878847caac9a734"
  }
};
