import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { AuthService } from './auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class InspectionReportService {
    public headers = new Headers();
    private API_URL: any = environment.API_URL;
    private API_PREFIX: any = environment.API_PREFIX

    constructor(
        private http: Http,
        public router: Router,
        public authService: AuthService,
    ) {
        // this.authService.createJsonHeader(this.headers);
        // this.authService.createAuthorizationHeader(this.headers)
    }

    public getInspectionReportSummary(date) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/inspectionreport/summary/' + date, {
                    headers: this.authService.generateHeaders()
                }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public getInspectionReportList(search = '', status, page, per_page, month = '', date = '') {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/inspectionreport?search=' + search +
                '&status=' + status + '&page=' + page + '&per_page=' + per_page + '&month=' + month + '&date=' + date, {
                    headers: this.authService.generateHeaders()
                }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public getWorkOrderList(search, status, page, per_page, month = '', year = '') {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/workorder?search=' + search +
                '&status=' + status + '&page=' + page + '&per_page=' + per_page + '&month=' + month + '&year=' + year, {
                    headers: this.authService.generateHeaders()
                }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public getWorkOrderListByID(inspection_id = '') {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/workorder?inspection=' + inspection_id + '&get_all=true', {
                    headers: this.authService.generateHeaders()
                }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public getWorkOrderByID(inspection_id) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/workorder/' + inspection_id, {
                    headers: this.authService.generateHeaders()
                }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public addDocument(values) {
        let formData = new FormData();

        formData.append('do_name', values.do_name);
        formData.append('do_category', values.do_category);
        formData.append('do_category_tag', values.do_category_tag);
        formData.append('do_file', values.do_file);

        return new Promise((resolve, reject) => {
            this.http.post(this.API_URL + '/' + this.API_PREFIX + '/document', formData, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    //show specific event details
    public getInspectionReportData(id) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/inspectionreport/' + id, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    //reject(error.json());
                })
        });
    }

    public downloadReportAsPDFByID(id) {
        return new Promise((resolve, reject) => {
            this.http.get(this.API_URL + '/' + this.API_PREFIX + '/inspectionreport/pdf/' + id, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    //reject(error.json());
                })
        });
    }

    public updateWorkOrderStatus(id, data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.API_URL + '/' + this.API_PREFIX + '/workorder/' + id + '?_method=PUT', data, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }


    public deleteDocument(id) {
        return new Promise((resolve, reject) => {
            this.http.delete(this.API_URL + '/' + this.API_PREFIX + '/document/' + id, {
                headers: this.authService.generateHeaders()
            }).map(res => res.json())
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    reject(error.json());
                })
        });
    }

    public stopParentNavigate():void {
        /* Stops navigating into update */
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
    }
}