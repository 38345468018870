import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { AuthService } from './auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class HelperService {
    public headers = new Headers();
    private API_URL: any = environment.API_URL;
    private API_PREFIX: any = environment.API_PREFIX

    /* File size type */
    private units = [
        'bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB'
    ];

    constructor(
        private http: Http,
        public router: Router,
        public authService: AuthService,
    ) {}

    public stopParentNavigate():void {
        /* Stops navigating into update */
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
    }

    public getStatusCssClass(statusID) {
        switch(statusID) {
            case 10:
                return 'status-active';
            break;

            case 80:
                return 'status-warning';
            break;

            case 90:
                return 'status-warning';
            break;

            case 100:
                return 'status-inactive';
            break;

            default:
                return 'status-active';
            break;
        }
    }

    public getInspectionStatusCssClass(statusID) {
        if(statusID == 'p') {
            return 'status-active';
        } else {
            return 'status-inactive';
        }
    }

    public formatByFileSize(bytes: number = 0, precision: number = 2 ) : string {
        if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';
    
        let unit = 0;
    
        while ( bytes >= 1024 ) {
            bytes /= 1024;
            unit ++;
        }
    
        return bytes.toFixed( + precision ) + ' ' + this.units[ unit ];
    }

    public formatDate(date, withTime = false)
    {
        if(withTime) {
            return moment(date).format('YYYY-MM-DD HH:mm a');
        } else {
            return moment(date).format('YYYY-MM-DD');
        }
    }

    public getListOfYears(yearsBehind = 6)
    {
        var listOfYears = [];
        var currentYear = moment();

        for(var i = 0; i < yearsBehind; i++) {
            if(i == 0 ) {
                listOfYears.push(currentYear.format('YYYY'));
            } else {
                listOfYears.push(moment(listOfYears[i - 1]).subtract(1, 'years').format('YYYY'));
            }  
        }
        
        return listOfYears;
    }

    public transformKeyObject(value):any {
        let keys = [];
        for (let key in value) {
            keys.push({key: key, value: value[key]});
        }
        return keys;
    }

}