import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import * as moment from 'moment';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  type: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

export const ROUTES: RouteInfo[] = [
  {
    path: '/inspection',
    title: 'Inspections',
    icon: '',
    class: '',
    type: 'sub',
    children: [
      { path: 'list-report', title: 'Reports', ab: 'RP' },
      { path: 'list-work-order', title: 'Work Orders', ab: 'WO' }
    ]
  },
  {
    path: '/event',
    title: 'Event',
    icon: '',
    class: '',
    type: 'sub',
    children: [
      { path: 'list-event', title: 'Event List', ab: 'LE' },
      { path: 'create-event', title: 'Event Create', ab: 'CE' },
    ]
  },
  {
    path: '/staff',
    title: 'Staff',
    icon: '',
    class: '',
    type: 'sub',
    children: [
      { path: 'list-staff', title: 'Staff List', ab: 'LS' },
      { path: 'create-staff', title: 'Staff Create', ab: 'CS' },
    ]
  },
  {
    path: '/ride',
    title: 'Ride',
    icon: '',
    class: '',
    type: 'sub',
    children: [
      { path: 'list-ride', title: 'Ride List', ab: 'LR' },
      { path: 'create-ride', title: 'Ride Create', ab: 'CR' },
    ]
  },
  {
    path: '/checklist-item',
    title: 'CheckList_Item',
    icon: '',
    class: '',
    type: 'sub',
    children: [
      { path: 'list-checklist-item', title: 'Check List Item List', ab: 'LCLI' },
      { path: 'create-checklist-item', title: 'Check List Item Create', ab: 'CCLI' },
    ]
  },
  {
    path: '/sparepart',
    title: 'SparePart',
    icon: '',
    class: '',
    type: 'sub',
    children: [
      { path: 'list-sparepart', title: 'Spare Part List', ab: 'LSP' },
      { path: 'create-sparepart', title: 'Spare Part Create', ab: 'CSP' },
    ]
  },
  {
    path: '/document/list-document',
    title: 'Documents Management',
    icon: '',
    class: '',
    type: 'link'
  },
  {
    path: '/change-password/change-password',
    title: 'Change Password',
    icon: '',
    class: '',
    type: 'link'
  }
  
  // { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', type: 'link' },
  // { path: '/user-profile', title: 'User Profile', icon: 'person', class: '', type: 'link' },
  // { path: '/table-list', title: 'Table List', icon: 'content_paste', class: '', type: 'link' },
  // { path: '/typography', title: 'Typography', icon: 'library_books', class: '', type: 'link' },
  // { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '', type: 'link' },
  // { path: '/maps', title: 'Maps', icon: 'location_on', class: '', type: 'link' },
  // { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '', type: 'link' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private authService: AuthService, public router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  logout() {

    Swal.fire({
      title: 'Confirm logout?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
        if (result.value) {
          this.authService.logout();
          this.router.navigate(['/pages/login']);
          
          $.notify({
            icon: "add_alert",
            message: 'Logout successfully at ' + moment().format('h:MM a')
            }, {
                type: 'success',
                timer: 4000,
                placement: {
                    from: 'top',
                    align: 'center'
                }
            });

        } else if (result.dismiss === Swal.DismissReason.cancel) {}
    })
    
  }

  ngAfterViewInit() {
    var $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();
    var collapseId = $sidebarParent.siblings('a').attr("href");

    $(collapseId).collapse('show');
  }

  formatTitle(title) {
    switch (title) {
      case 'Event':
        return 'Events Management';
      break;

      case 'Staff':
        return 'Staffs Management';
      break;

      case 'Ride':
        return 'Rides Management';
      break;

      case 'CheckList_Item':
        return 'Checklist Management';
      break;

      case 'SparePart':
        return 'Spare Part Management';
      break;

      case 'Inspections':
        return 'Inspections';
      break;

      case 'Document':
        return 'Documents Management';
      break;

      case '':
        return 'Change_password';
      break;
    }
  }
}
