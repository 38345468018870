import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Http, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { MessagingService } from "../messaging.service";
import { UserIdleService } from 'angular-user-idle';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public headers = new Headers();
  API_URL: any = environment.API_URL;
  API_PREFIX: any = environment.API_PREFIX;
  CLIENT_SECRET: any = environment.client_secret;
  CLIENT_ID: any = environment.client_id;
  GRANT_TYPE: any = environment.grant_type;
  message;

  constructor(private userIdle: UserIdleService, private http: Http, public jwtHelper: JwtHelperService, private messagingService: MessagingService) {
    this.createJsonHeader(this.headers);
  }

  createJsonHeader(headers: Headers) {
    headers.append('Accept', 'application/json');
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Bearer ' + this.localStorageItem('token'));
  }

  public localStorageItem(item): string {
    return localStorage.getItem(item);
  }

  public generateHeaders() {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    if (this.localStorageItem('token')) {
      headers.append('Authorization', 'Bearer ' + this.localStorageItem('token'));
    }
    return headers;
  }

  login(data) {
    const authData = new FormData();
    authData.append('grant_type', this.GRANT_TYPE);
    authData.append('client_id', this.CLIENT_ID);
    authData.append('client_secret', this.CLIENT_SECRET);
    authData.append('username', data.username);
    authData.append('password', data.password);
    authData.append('scopes', data.scopes);

    if (localStorage.getItem('fcm_token') != 'null') {
      authData.append('device_token', localStorage.getItem('fcm_token'));
      authData.append('platform', '3');
    }
    
    if(data.user_agent_string) {
      authData.append('user_agent_string', data.user_agent_string);
    }
    
    return new Promise((resolve, reject) => {
      this.http.post(this.API_URL + '/' + this.API_PREFIX + '/auth/login', authData)
        .map(res => res.json())
        .subscribe(
          data => {
            if (data.data.token_type === 'Bearer') {
              localStorage.setItem('token', data.data.access_token);
              localStorage.setItem('user', data.data.user);
              localStorage.setItem('full_name', data.data.user.full_name);
              localStorage.setItem('login_time', moment().format('YYYY-MM-DD HH:mm:ss'));
              // let role_id = Object.keys(data.data.role);
              // localStorage.setItem('role_id', role_id[0]);
              resolve(data);
            }
          },
          error => {
            reject(error.json());
          });
    });
  }

  logout() {
    this.userIdle.stopWatching();
    var save_fcm_token = localStorage.getItem('fcm_token');
    localStorage.clear();
    localStorage.setItem('fcm_token',save_fcm_token);

    return new Promise((resolve, reject) => {
      this.http.post(this.API_URL + '/' + this.API_PREFIX + '/auth/fcmkey/clear/' + save_fcm_token, {})
      .map(res => res.json())
         .subscribe(data => {
         }, error => {
         })
   });
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public changePassword(values) {
    let formData = new FormData();

    formData.append('current_password', values.current_password);
    formData.append('password', values.password);
    formData.append('password_confirmation', values.password_confirmation);

    return new Promise((resolve, reject) => {
        this.http.post(this.API_URL + '/' + this.API_PREFIX + '/changepassword', formData, {
            headers: this.generateHeaders()
        }).map(res => res.json())
            .subscribe(data => {
                resolve(data);
            }, error => {
                reject(error.json());
            })
    });
  }
  
}
